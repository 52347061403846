/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*Photo by <a href="https://unsplash.com/@bel2000a?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Belinda Fewings</a> on <a href="https://unsplash.com/s/photos/stacking?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {AbsoluteChart, RelativeDemo, AbsoluteDemo, StickyDemo} from "./";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a",
    code: "code",
    pre: "pre",
    em: "em",
    br: "br",
    h3: "h3",
    blockquote: "blockquote",
    h4: "h4",
    h5: "h5",
    ol: "ol"
  }, _provideComponents(), props.components), {Aside, CodePen} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!CodePen) _missingMdxReference("CodePen", true);
  return React.createElement(React.Fragment, null, "\n", React.createElement(_components.p, null, "Positioning things on the web is …"), "\n", React.createElement(_components.p, null, "I’m bad at writing intros, let’s dive in."), "\n", React.createElement(_components.h2, {
    id: "document-flow"
  }, "Document flow"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "flow"), " is the way we refer to the browser laying out elements when there are no changes made to those elements."), "\n", React.createElement(_components.p, null, "Every element has a natural size, and ", React.createElement(_components.strong, null, "elements in flow affect the location of the elements around it"), "."), "\n", React.createElement(_components.p, null, "Boxes in normal flow are either block or inline, but not both at the same time."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Inline ones sit in the same direction as text."), "\n", React.createElement(_components.li, null, "Block ones are stacked along the cross axis."), "\n"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://www.w3.org/TR/CSS2/visuren.html#propdef-display"
  }, React.createElement(_components.code, null, "display"), " CSS property"), " can set this."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, "p {\n  display: block;\n}\n\nspan {\n  display: inline;\n}\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flow_Layout/Block_and_Inline_Layout_in_Normal_Flow#the_display_property_and_flow_layout"
  }, "Flexbox and grid ", React.createElement(_components.em, null, "containers"), " still participate in this block and inline layout."), "\nThat is why you can set the ", React.createElement(_components.code, null, "display"), " property to both ", React.createElement(_components.code, null, "flex"), " or ", React.createElement(_components.code, null, "inline-flex"), ".", React.createElement(_components.br), "\n", "Or to ", React.createElement(_components.code, null, "grid"), "/", React.createElement(_components.code, null, "inline-grid"), "."), React.createElement(_components.p, null, "Or, if you like the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/display#syntax"
  }, "two value display syntax"), ": ", React.createElement(_components.code, null, "display: block flex;"), " and ", React.createElement(_components.code, null, "display: inline flex"), ".")), "\n", React.createElement(_components.p, null, "There are 2 popular methods to take an element out of flow."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "absolute positioning, which we discuss later"), "\n", React.createElement(_components.li, null, "floating an element"), "\n"), "\n", React.createElement(_components.h2, {
    id: "position"
  }, "Position"), "\n", React.createElement(_components.p, null, "The default value for ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/position"
  }, "the CSS ", React.createElement(_components.code, null, "position"), " property"), " is ", React.createElement(_components.code, null, "static"), ".\nThat’s why you almost never see it written in code, when a ", React.createElement(_components.code, null, "position"), " is set explicitly, it’s usually with a different value."), "\n", React.createElement(_components.p, null, "The most important aspect is if the item gets taken out of ", React.createElement(_components.a, {
    href: "#document-flow"
  }, "flow"), " or not."), "\n", React.createElement(_components.p, null, "Values for ", React.createElement(_components.code, null, "position"), " that keep the element in the document flow:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "static")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "relative")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "sticky")), "\n"), "\n", React.createElement(_components.p, null, "Values for ", React.createElement(_components.code, null, "position"), " that remove the element from the document flow:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "absolute")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "fixed")), "\n"), "\n", React.createElement(_components.p, null, "Positioned elements can be offset by also defining a value for the ", React.createElement(_components.code, null, "top"), ", ", React.createElement(_components.code, null, "right"), ", ", React.createElement(_components.code, null, "bottom"), ", and ", React.createElement(_components.code, null, "left"), " properties with a distance."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "The demos in this blogpost use pixels for these offsets.\nThey can have many other values too!\nIncluding ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/length"
  }, "lengths (", React.createElement(_components.code, null, "rem"), ", ", React.createElement(_components.code, null, "em"), ", ", React.createElement(_components.code, null, "ch"), ", ", React.createElement(_components.code, null, "vw"), ", …)"), " and percentages."), React.createElement(_components.p, null, "The offsets can also have a negative value!")), "\n", React.createElement(_components.p, null, "Where that element is offset ", React.createElement(_components.em, null, "from"), " is determined by the value you chose for ", React.createElement(_components.code, null, "position"), "."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "Elements with ", React.createElement(_components.code, null, "position: static;"), " are positioned according to the normal document flow."), React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "top"), ", ", React.createElement(_components.code, null, "right"), ", ", React.createElement(_components.code, null, "bottom"), ", and ", React.createElement(_components.code, null, "left"), " properties don’t have an effect."), React.createElement(_components.p, null, "They are about as useful as ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=hkaBT-u7qn8"
  }, "Radioactive man’s goggles"), ".\nSo, not at all.")), "\n", React.createElement(_components.h3, {
    id: "relative"
  }, "Relative"), "\n", React.createElement(_components.p, null, "Relatively positioned elements remain in flow.\nThey are offset relative to their original position."), "\n", React.createElement(_components.p, null, "That final offset position has no effect on other elements,\nthey are laid out in the regular flow as if nothing happend."), "\n", React.createElement(RelativeDemo), "\n", React.createElement(_components.p, null, "If you played around with the interactive demo above, you might have noticed setting a ", React.createElement(_components.code, null, "bottom"), " had no effect until you disabled ", React.createElement(_components.code, null, "top"), ".\nThe ", React.createElement(_components.code, null, "top"), " property takes priority."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "bottom"), " is ignored if CSS sees both properties are set."), "\n", React.createElement(_components.p, null, "The same thing happens with ", React.createElement(_components.code, null, "left"), " and ", React.createElement(_components.code, null, "right"), ".\nIf CSS sees both, ", React.createElement(_components.code, null, "left"), " gets priority."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In left to right writing modes, ", React.createElement(_components.code, null, "left"), " has priority."), "\n", React.createElement(_components.li, null, "In right to left writing modes, ", React.createElement(_components.code, null, "right"), " has priority."), "\n")), "\n", React.createElement(_components.h3, {
    id: "absolute"
  }, "Absolute"), "\n", React.createElement(_components.p, null, "An absolutely positioned element is removed from flow.\nOther elements are not affected by where the element is displayed on the page."), "\n", React.createElement(_components.p, null, "The absolute element is offset relative to the nearest positioned ancestor.\nIn other words: the closest element up the tree with a ", React.createElement(_components.code, null, "position"), " set (that isn’t ", React.createElement(_components.code, null, "static"), ")."), "\n", React.createElement(_components.p, null, "If no ", React.createElement(_components.code, null, "top"), ", ", React.createElement(_components.code, null, "right"), ", ", React.createElement(_components.code, null, "bottom"), ", or ", React.createElement(_components.code, null, "left"), " offsets are set, the element is shown where it would be had it been in flow.\nBecause it was removed from the document flow, that means it will probably overlap the element after it though."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "In the demo below, this doesn’t appear to be the case.\nBut try setting ", React.createElement(_components.code, null, "position: absolute;"), " on an element without any other styles.\nIt will stay in place, and items after it will move to fill up the space in flow it just left")), "\n", React.createElement(_components.p, null, "An absolutely positioned element is as small as it can be, based on its content."), "\n", React.createElement(AbsoluteDemo), "\n", React.createElement(_components.p, null, "Contrary to the ", React.createElement(_components.code, null, "relative"), " demo, setting both ", React.createElement(_components.code, null, "left"), " and ", React.createElement(_components.code, null, "right"), " at the same time does work."), "\n", React.createElement(_components.p, null, "It will respect both unless a ", React.createElement(_components.code, null, "width"), " is set.\nIn that case you’re asking the browser to satisfy two conflicting commands and it will ignore ", React.createElement(_components.code, null, "right"), " again."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "When both left and right are defined, and width constraints don’t prevent it, the element will stretch to satisfy both.\nIf the element cannot stretch to satisfy both, the position of the element is overspecified.\nWhen this is the case, the left value has precedence when the container is left-to-right; the right value has precedence when the container is right-to-left."), "\n", React.createElement("footer", null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/left"
  }, "The MDN docs"))), "\n"), "\n", React.createElement(_components.p, null, "So, ", React.createElement(_components.code, null, "position: absolute;"), " is pretty cool, and unlike ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=wgpytjlW5wU"
  }, "that space trek quote"), ", I also deal in absolutes."), "\n", React.createElement(_components.h4, {
    id: "one-two-punch"
  }, "One two punch"), "\n", React.createElement(_components.p, null, "It is a very popular practice to set ", React.createElement(_components.code, null, "position: relative;"), " on the direct parent of an element with ", React.createElement(_components.code, null, "position: absolute;"), "."), "\n", React.createElement(_components.p, null, "That way the provided offsets will be offsets from that parent, which is usually what you want."), "\n", React.createElement(_components.p, null, "The results ", React.createElement(_components.em, null, "might"), " be identical if you didn’t do that, but it’s good to avoid unexpected results."), "\n", React.createElement(_components.h4, {
    id: "tweaking"
  }, "Tweaking"), "\n", React.createElement(_components.p, null, "Setting a ", React.createElement(_components.code, null, "top"), ", ", React.createElement(_components.code, null, "right"), ", ", React.createElement(_components.code, null, "bottom"), ", or ", React.createElement(_components.code, null, "left"), " offset would lose the element’s original position."), "\n", React.createElement(_components.p, null, "If that position is not quite right, adding a ", React.createElement(_components.code, null, "margin"), " is a useful tool.\nIt will affect the location of the absolutely positioned element but nothing else, as it is out of flow."), "\n", React.createElement(_components.h4, {
    id: "centering"
  }, "Centering"), "\n", React.createElement(_components.p, null, "The method of placing an item with offsets makes it a bit awkward to exactly center things."), "\n", React.createElement(_components.p, null, "We don’t need to rely on ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Magic_number_(programming)"
  }, "magic numbers"), " for those values however, a few techniques exist."), "\n", React.createElement(_components.h5, {
    id: "margin"
  }, "Margin"), "\n", React.createElement(_components.p, null, "Setting the ", React.createElement(_components.code, null, "top"), " and ", React.createElement(_components.code, null, "bottom"), " to 0, with a ", React.createElement(_components.code, null, "margin-top"), " of ", React.createElement(_components.code, null, "auto"), " and a ", React.createElement(_components.code, null, "margin-bottom"), " of ", React.createElement(_components.code, null, "auto"), " will vertically center the absolutely positioned element.\nThe same logic can be applied to ", React.createElement(_components.code, null, "left"), ", and ", React.createElement(_components.code, null, "right"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css",
    title: "styles.css"
  }, ".absolute-center {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  margin: auto;\n  width: 100px; /* has to be known for horizontal centering */\n  height: 100px; /* has to be known for vertical centering */\n}\n")), "\n", React.createElement(_components.h5, {
    id: "transform"
  }, "Transform"), "\n", React.createElement(_components.p, null, "A solution that doesn’t need known dimensions leverages the ", React.createElement(_components.code, null, "transform"), " property."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Move the element’s top left edge to be in the exact center of the parent."), "\n", React.createElement(_components.li, null, "Scoot the element over by half the size of that element with a ", React.createElement(_components.code, null, "transform"), "."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css",
    title: "styles.css"
  }, ".absolute-center {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n")), "\n", React.createElement(_components.p, null, "Both the margin and transform methods can be seen in ", React.createElement(_components.a, {
    href: "https://codepen.io/NMeuleman/pen/GREZpey"
  }, "this codepen"), "."), "\n", React.createElement(_components.h5, {
    id: "flexbox-and-grid"
  }, "Flexbox and grid"), "\n", React.createElement(_components.p, null, "This surprised me (because I thought flexbox and grid would have no effect on items that are out of flow),\nbut flexbox and grid can be used to place absolutely positioned elements!"), "\n", React.createElement(CodePen, {
    codePenId: "OJgpOrL"
  }), "\n", React.createElement(_components.h3, {
    id: "fixed"
  }, "Fixed"), "\n", React.createElement(_components.p, null, "Fixed positioning is a specialized form of absolute positioning.\nThe offsets are relative to the viewport, even when the page is scrolled."), "\n", React.createElement(_components.h3, {
    id: "sticky"
  }, "Sticky"), "\n", React.createElement(_components.p, null, "Elements with ", React.createElement(_components.code, null, "position: sticky"), " are offset relative to nearest scrolling\nancestor."), "\n", React.createElement(_components.p, null, "That enables ", React.createElement(_components.a, {
    href: "https://mastery.games/post/position-sticky/"
  }, "a bunch of neat applications")), "\n", React.createElement(StickyDemo), "\n", React.createElement(_components.p, null, "Sticky elements don’t always have to be offset from the top.\nUsing multiple offsets works too."), "\n", React.createElement(_components.p, null, "The scrolling also doesn’t have to be vertical."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://codepen.io/NMeuleman/pen/VwWvNqg"
  }, "This CodePen"), " has a horizontally scrolling parent with a sticky child that has a ", React.createElement(_components.code, null, "right"), " set."), "\n", React.createElement(_components.h2, {
    id: "conclusion"
  }, "Conclusion"), "\n", React.createElement(_components.p, null, "Positioning allows us to take elements out of the ", React.createElement(_components.em, null, "normal"), " ", React.createElement(_components.a, {
    href: "#flow"
  }, "document flow"), "."), "\n", React.createElement(_components.p, null, "Some values remove an item from flow entirely, others don’t."), "\n", React.createElement(_components.p, null, "Elements in flow take up space, they affect elements next to them."), "\n", React.createElement(AbsoluteChart), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "In this post I completely glossed over the effects CSS ", React.createElement(_components.code, null, "position"), " has on the stacking order."), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context"
  }, "Stacking contexts"), " are a big topic.\nArmed with the knowledge of this blogpost, understanding them will happen a bit quicker.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
